html {
    font-family: "NotoSans", Helvetica, Arial, sans-serif;
    font-size: 100%;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
}

body {
    padding: 0;
    margin: 0;
    overflow-y: hidden;
}

/*
@font-face {
    font-family: "HelveticaNeue";
    src: url("/static/fonts/HelveticaNeue Light.ttf") format("truetype");
}

@font-face {
    font-family: "HelveticaNeueThin";
    src: url("/static/fonts/HelveticaNeue Thin.ttf") format("truetype");
}

@font-face {
    font-family: "NotoSans";
    src: url("/static/fonts/NotoSans-Regular.ttf") format("truetype");
}
*/

.normal {
    font-size: 1rem;
    font-weight: 400;
}

/*@media ${MEDIA_QUERIES.SM} {
  font-size: 87.5%;
}*/

div.sts-standard {
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 400;
    margin: 0 auto;
}

/* Top section of document */
h1.sts-standard-designation {
    margin: 0px;
    font-size: 1.5em;
    font-weight: 500;
}

div.sts-standard-title {
    margin-top: 20px;
    font-size: 1.5em;
    font-weight: 500;
}

/* Generic settings for common element. */
/* Section based on heading levels */
.heading-level-1 {
    font-size: 1.25rem;
    font-weight: 600;
}

.heading-level-2 {
    font-size: 1.125rem;
    font-weight: 600;
}

.heading-level-3,
.heading-level-4,
.heading-level-5,
.heading-level-6 {
    font-size: 1rem;
    font-weight: 600;
}

/* All section have a bottom padding */
div.sts-section {
    /* margin-bottom: 40px; */
}

div.sts-sec-title {
    padding-bottom: 10px;
}

div.sts-section,
div.sts-section .sts-p,
div.sts-section td,
div.sts-copyright,
div.sts-app {
    font-size: 1rem;
}

div.sts-app,
div.sts-footnotes {
    margin-bottom: 40px;
}

div.sts-p {
    clear: both;
    margin: 0px 0 10px 0;
    position: relative;
}

div.commentary-first-p {
    display: inline;
}

/* Caption for figures */
div.sts-caption {
    margin: 10px;
    font-weight: 600;
}

/* Generic setting for all fig */
div.sts-app h1 {
    font-size: 1.25rem;
    color: black;
    font-weight: 600;
}

/* figures */
div.sts-fig,
div.sts-fig-group {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    clear: both;
}

div.sts-fig-group-content {
    display: inline-block;
}

div.sts-fig.float-left {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    clear: none;
}

.sts-fig img {
    max-width: 90%;
    height: auto;
    margin: 10px;
}

.sts-section img {
    max-width: 90%;
    height: auto;
    margin: 10px;
}

div.sts-fig table {
    margin-left: auto;
    margin-right: auto;
    border-spacing: 0;
}

/* index tables */
div.sts-table-wrap.fig-index,
div.sts-table-wrap.fig-index table,
div.sts-table-wrap.formula-index,
div.sts-table-wrap.formula-index table {
    text-align: left;
    margin-left: 10px;
}

/* fig footnotes and non-normative-note */
div.sts-fig div.sts-non-normative-note,
div.sts-fig div.footnote,
div.sts-fig div.sts-p,
div.sts-fig div.sts-array-content {
    text-align: left;
    margin-left: 20px;
}

span.sts-non-normative-note-label {
    margin-right: 10px;
}

/* formal tables */
div.sts-table-wrap {
    margin-top: 25px;
    text-align: center;
    overflow-x: auto;
}

div.sts-table-wrap:not(.fig-index) table {
    margin-left: auto;
    margin-right: auto;
    border-spacing: 0;
    border-collapse: collapse;
}

div.sts-table-wrap-foot {
    text-align: left;
}

/* ref lists */
table.sts-ref-list {
    border: none;
    /* To make sure word breaks currently on all browsers in case it is overflown */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

table.sts-ref-list td {
    vertical-align: text-top;
    padding-bottom: 10px;
}

table.sts-ref-list td.sts-label {
    width: 30px;
}

ul.sts-ref-list {
    list-style-type: none;
    padding-left: 0;
}

ul.sts-ref-list li {
    padding-bottom: 10px;
}

span.sts-std-title {
    font-style: italic;
}

/* Math MML */
div.sts-disp-formula-label {
    width: 10%;
    float: right;
    text-align: right;
    margin-left: 10px;
}

/* tbx */
div.sts-tbx-sec {
    margin-top: 15px;
}

div.sts-tbx-label,
div.sts-tbx-term {
    font-weight: bold;
    clear: both;
}

div.deprecatedTerm,
.sts-tbx-term-not-bold {
    font-weight: normal;
}

div.sts-tbx-def {
    margin-top: 10px;
}

div.sts-tbx-term.admittedTerm {
    font-weight: normal;
    clear: both;
}

.sts-tbx-entailedTerm,
.sts-tbx-entailedTerm a {
    font-style: italic;
}

span.sts-tbx-crossReference,
span.sts-tbx-crossReference a {
    font-weight: bold;
    text-decoration: none;
    color: black;
}

.sts-tbx-entailedTerm a:hover,
.sts-tbx-crossReference a:hover,
.sts-tbx-see a:hover {
    background-color: lightgrey;
}

.sts-tbx-entailedTerm-num,
.sts-tbx-entailedTerm-num a {
    font-weight: normal;
    font-style: normal;
}

div.sts-tbx-example {
    overflow: hidden;
    /* To make sure the div height is calculated properly. If the childs are floated, parent's height is not calculated.*/
}

div.sts-tbx-example-label {
    float: left;
    padding-right: 15px;
}

div.sts-tbx-example-content {
    float: left;
    width: 90%;
    margin-bottom: 10px;
}

div.sts-tbx-note,
div.sts-tbx-example,
div.sts-tbx-see,
div.sts-tbx-source,
div.sts-tbx-crossReference {
    margin-top: 10px;
    clear: both;
}

.sts-tbx-see a {
    text-decoration: none;
    color: #002F5F;
}

/* appendixes */
h1.sts-app-header {
    font-size: 1.25rem;
    text-align: left;
}

a.sts-std-ref {
    text-decoration: underline;
    color: #002F5F;
}

a.sts-std-ref:hover {
    background-color: lightgrey;
}

/* footnotes */
.footnote,
.footnote .sts-p {
    text-align: left;
    font-size: 0.875rem;
    color: black;
}

/* cross refs */
a.sts-xref {
    text-decoration: underline;
    color: #002F5F;
}

a.sts-xref:hover {
    background-color: lightgrey;
}

/* lists */
/* All lists comes with a default left margin, we don't want that/*/
.list ul,
.list ol {
    margin-left: -20px;
}

/* The indentation for a list item paragraph should be correct. Because the labels are attached with the list item directly, we need to
set the negative text-indent for first para.*/
.li-with-label>div.sts-p:first-of-type {
    text-indent: -1.2em !important;
    margin-left: 0px !important;
}

.li-without-label>.sts-p {
    text-indent: 0em !important;
    margin-left: -20px !important;
}

/* Any formula inside a list should have proper left-margin.*/
li>div.sts-p:first-of-type>.sts-disp-formula-panel {
    text-indent: 0em !important;
}

.sts-non-normative-note>.sts-p {
    margin-left: 0px !important;
}

/* non-normative-note */
.sts-non-normative-note {
    text-align: left;
    margin: 10px 0 10px 0;
}

/* Font size for note need to be smaller */
.sts-non-normative-note:not(.exception) .sts-p,
.sts-non-normative-note pre,
.sts-non-normative-note ul,
.sts-non-normative-note ol,
.sts-non-normative-note td,
.sts-tbx-note,
.sts-tbx-note .sts-p {
    font-size: 0.875rem !important;
}

.exception>.sts-p,
.exception ul,
.exception ol {
    font-style: italic;
    font-size: 1rem !important;
}

.help .sts-p,
.help ul,
.help ol,
.caution .sts-p,
.caution ul,
.caution ol,
.danger .sts-p,
.danger ul,
.danger ol,
.important .sts-p,
.important ol,
.important ul,
.safety-precautions .sts-p,
.safety-precautions ul,
.safety-precautions ol,
.warning .sts-p,
.warning ul,
.warning ol {
    font-weight: bold;
    font-style: normal;
    font-size: 1rem !important;
}

/* preformat */
.sts-preformat {
    margin: 0;
    white-space: pre-wrap;
    /* css-3 */
    white-space: -moz-pre-wrap;
    /* Mozilla */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
}

.sts-preformat.code-allow-wrap {
    white-space: normal;
}

/* boxed-text */
.sts-boxed-text {
    border-style: solid;
    border-width: 1px;
    padding: 5px;
}

/* All the text inside the commentary box should be italic.*/
.commentary>.sts-p,
.commentary>.sts-fig,
.commentary>.sts-table-wrap>.sts-caption {
    font-style: italic;
}

/* All MathJax formulaes should be aligned left. */
.MathJax {
    display: inline !important;
}

.sts-disp-formula-panel {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}

.sts-disp-formula-container {
    width: 90%;
    float: left;
}

.sts-boxed-text-commentary-label {
    float: left;
    font-style: italic;
    font-weight: bold;
    margin-right: 5px;
}

/* p-styles */
.sts-p.align-center {
    text-align: center;
}

.sts-p.align-right {
    text-align: center;
}

.sts-p.align-left {
    text-align: center;
}

.sts-p.align-left.valign-top {
    vertical-align: top;
}

.sts-p.valign-bottom {
    vertical-align: bottom;
}

.sts-p.valign-middle {
    vertical-align: middle;
}

.sts-partial-display-note {
    /* max-width: 800px; */
    text-align: center;
}

.sts-partial-display-note-text {
    padding: 10px;
    background-color: #afc6e1;
    border: 3px solid #e8edf0;
    text-align: center;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5x;
    -khtml-border-radius: 5px;
    border-radius: 5px;
}

.sts-protected-content-note {
    margin-top: 10px;
    clear: both;
    /* max-width: 800px; */
    background-color: #afc6e1;
}

/* .sts-protected-content-note-top {
    background: url(images/text-break-top.png);
    background-repeat: repeat-x;
    background-position: top;
    height: 14px;
}
.sts-protected-content-note-bottom {
    background: url(images/text-break-bottom.png);
    background-repeat: repeat-x;
    background-position: bottom;
    height: 14px;
} */
.sts-protected-content-note-text {
    margin: 2px;
    text-align: center;
    font-weight: bold;
}

.sts-protected-content-inpreview-text {
    margin-bottom: 10px;
    text-align: center;
    font-size: 0.875rem;
}

/* unknown elements */
.sts-unknown-element {
    color: red;
}

.sts-table-wrap td {
    padding: 3px;
}

.sts-footnotes hr {
    width: 200px;
    text-align: left;
    margin-left: 0;
}

.sts-copyright {
    text-align: right;
}

/* needed for iframe content */
.iframe-body {
    overflow: auto;
    margin: 0;
    padding: 0;
    border-style: none;
    border: 0;
}

#scrollwrapper {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}

.hit {
    /*background-color: yellow;*/
    background: #fff200;
}

.currhit {
    background-color: orange;
}

.sts-table-wrap table {
    border-color: black;
    text-indent: 0em;
}

.sts-table-wrap th {
    font-weight: normal;
}

div.dimension {
    text-align: right;
    font-weight: normal;
}

div.sts-fn {
    margin-top: 5px;
}

img.math {
    vertical-align: middle;
    border: 0;
}

.sts-hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #dddddd;
    margin: 1em 0;
    margin-top: 20px;
    margin-bottom: 20px;
}

.sts-drafting-note {
    padding: 3px;
    margin: 5px 0 5px 0;
    border: 1px solid black;
}

.sts-array-content table {
    width: 100%;
    border-collapse: collapse;
}

td {
    padding: 5px;
}

mjx-c {
    padding-left: 1px;
    padding-right: 1px;
}

/* Body paragraph styles */
.sts-p.indent,
.sts-p.index_1,
.sts-p.index1,
.blockquote {
    margin-left: 36px;
}

.sts-p.indent2,
.sts-p.index_2,
.sts-p.index2 {
    margin-left: 72px;
}

.sts-p.indent3,
.sts-p.index_3,
.sts-p.index3 {
    margin-left: 108px;
}

.sts-p.indent4,
.sts-p.index_4,
.sts-p.index4 {
    margin-left: 144px;
}

.sts-non-normative-example {
    background-color: #DCDCDE;
}

.sts-p-index_3 {
    margin-left: 36px;
}

sub,
sup {
    padding-left: 2px;
}

/* About this publication section */
div.sts-about {
    font-size: 1em;
    margin-bottom: 40px;
}

div.sts-amnd-version {
    font-size: 1em;
}

.sts-table-about {
    border-collapse: collapse;
    border: 0px;
    margin-bottom: 20px;
}

.sts-table-about .sts-about-label {
    padding-left: 0px;
    vertical-align: top;
}

.sts-about-label {
    color: black;
    font-weight: 600;
}

.sts-table-about .sts-about-value {
    padding-left: 20px;
    vertical-align: top;
    font-weight: normal;
}

.sts-about-copyright-holder,
.sts-about-copyright-statement,
.sts-about-identical-adoption,
.sts-about-modified-adoption,
.sts-about-license-statement,
.sts-about-cover-page {
    margin-bottom: 10px;
}

.amnd-marker {
    font-size: 1rem;
    font-style: normal;
    text-indent: 0px !important;
    /* To make sure there is no effect on any text-indent set on the parent.*/
    position: absolute;
    padding-right: 5px;
    border-right: 2px solid black;
    left: -33px !important;
    height: 100%
}

ul .amnd-marker {
    left: -45px !important;
}

.amnd-marker-dated-indicator {
    position: absolute;
    padding-left: 5px;
    border-left: 2px solid #CA2424;
}

.amnd-marker-dated-text {
    color: #CA2424;
    text-align: left;
    font-size: 0.8rem;
    font-style: normal;
    text-indent: 0px !important;
    /* To make sure there is no effect on any text-indent set on the parent.*/
}

li>.sts-p>.amnd-marker-dated-text {
    text-indent: -1.2em !important;
}

.new-marker {
    font-size: 1rem;
    font-style: normal;
    text-indent: 0px !important;
    /* To make sure there is no effect on any text-indent set on the parent.*/
    position: absolute;
}

.region-marker-au,
.region-marker-nz {
    font-size: 1rem;
    font-style: normal;
    text-indent: 0px !important;
    /* To make sure there is no effect on any text-indent set on the parent.*/
    border: 1px solid black;
    padding: 2px;
    position: absolute;
}


.search-result__clicked {
    background-color: #B4D8FF;
}

/* Making sure elements looks fine on mobile */
@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    div.sts-about {
        margin-bottom: 20px;
    }

    div.sts-section {
        /* margin-bottom: 20px; */
    }

    div.sts-table-wrap table,
    .sts-array-content table {
        width: 100% !important;
        overflow-x: auto;
        display: block;
    }

    div.sts-disp-formula-panel mjx-container {
        font-size: 100% !important;
    }

    div.sts-disp-formula-panel {
        overflow-x: auto;
        text-indent: 0px !important;
    }

    div.sts-disp-formula-container {
        text-indent: 0px !important;
        overflow-x: auto;
    }

    .sts-preformat {
        overflow-x: auto;
    }
}