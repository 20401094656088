body {
  font-family: "Noto Sans", Helvetica, Arial, sans-serif !important;
}

#page-spinner {
  margin: 25% 25% 0% 50%;
}

.center {
  margin: 25% 25% 0% 50%;
}